import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { Title } from '@angular/platform-browser';
import { Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
 
  private islogedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isNavigationCollapse$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
   private titleService: Title,
   private authService: MsalService,
   private router: Router,
    ) { }

  setPageTitle(title: string) {
    this.titleService.setTitle(title);
  }

  isLoginSet(val: boolean) {
    this.islogedIn$.next(val);
  }
  isLoginGet() {
    return this.islogedIn$.asObservable();
  }
  isLoginGetByValue() {
    return this.islogedIn$.getValue();
  }

  isNavigationCollapseSet(val: boolean) {
    this.isNavigationCollapse$.next(val);
  }
  isNavigationCollapseGet() {
    return this.isNavigationCollapse$.asObservable();
  }
  isNavigationCollapseByValue() {
    return this.isNavigationCollapse$.getValue();
  }
  
  logout()
  {
    this.authService.logoutRedirect();
  }

 
}
