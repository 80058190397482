import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../services/storage.service';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-accessdenied',
  templateUrl: './accessdenied.component.html',
  styleUrls: ['./accessdenied.component.scss']
})
export class AccessdeniedComponent  implements OnInit {
  
  constructor(
    private _storageService: StorageService, private navService: NavigationService,) {
  }

  ngOnInit(): void {
    this._storageService.removeDataFromStorage('access_denied');
  }

  logout(){
    this.navService.logout();
  }
  
}
