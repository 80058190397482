import { Component } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';
import { environment } from '../environments/environment';
declare const gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  
{
  constructor(private router: Router) {
    this.addGAScript();
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
          gtag('event', 'page_view', 
                {
                  page_path: event.urlAfterRedirects
                }
           );
       }
    })
  }
    
  /** Add Google Analytics Script Dynamically */
 addGAScript() 
 {
  if(environment.googleAnalytic ==='uat' || environment.googleAnalytic ==='local' || environment.googleAnalytic ==='dev' || environment.googleAnalytic ==='prod'){
    (function(w:any,d:any,s:any,l:any,i:any){w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      let f:any=d.getElementsByTagName(s)[0];
      let j:any=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer',`${environment.googleTagManager}`);
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticKey;
    document.head.prepend(gtagScript);
  
    let gUAtagScript: HTMLScriptElement = document.createElement('script');
    gUAtagScript.async = true;
    gUAtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' +  environment.googleAnalyticUAKey;
    document.head.prepend(gUAtagScript);
    gtag('config', environment.googleAnalytic, { send_page_view: false });
    
    gtag('config', environment.googleAnalyticUAKey,{
      'custom_map': {
        'dimension1': 'userName'
      }
   });
  
   
   let noscript = document.createElement('noscript')
    let iframe = document.createElement('iframe');
    iframe.setAttribute('src',`https://www.googletagmanager.com/ns.html?id=${environment.googleTagManager}`)
    iframe.setAttribute("height", "0")
    iframe.setAttribute("width", "0")
    noscript.appendChild(iframe)
    document.body.appendChild(noscript);
    }
  }
}