import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessdeniedComponent } from './page/accessdenied/accessdenied.component';


const routes: Routes = [
  {
    path: 'access-denied',
    component: AccessdeniedComponent,
    data :{title:'Access denied'}
  },
  { 
    path: '', 
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontRoutingModule { }
