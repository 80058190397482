import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FrontRoutingModule } from './front-routing.module';
import { GraphQLModule } from '../graphql.module';
import { SharedModuleModule } from './shared-module/shared-module.module';
import { PagesModule } from '../front/page/pages.module';


@NgModule({
  declarations: [
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FrontRoutingModule,
    GraphQLModule,
    PagesModule,
    SharedModuleModule
  ],
})
export class FrontModule { }
