import { NgModule } from '@angular/core';
import { AccessdeniedComponent } from './accessdenied/accessdenied.component';
import { SharedSnlComplibModule } from '../shared-module/shared-snl-complib.module'





@NgModule({
  declarations: [
    AccessdeniedComponent,
   ],
  imports: [
    SharedSnlComplibModule
  ],
  providers: [
  ]
})
export class PagesModule { }
