  export const environment = {
  production: false,
  graphQL_URL:'https://api.stoltdev.com/pooling-portal/graphql',
  navType: 'dev',
  B2C_CLIENTID:'15839d5c-3a67-4ef9-a767-77d7b40169d2',
  AUTHORITY:'https://stoltdevb2c.b2clogin.com/stoltdevb2c.onmicrosoft.com/B2C_1A_STDIGITAL_SIGNUP_SIGNIN',
  KNOWNAUTHORITIES:['stoltdevb2c.b2clogin.com'],
  USERSCOPE:'https://stoltdevb2c.onmicrosoft.com/2c174889-6d24-4141-9931-c091f8b219c6/read',
  GRAPH_ENDPONT:'https://graph.microsoft.com/v1.0/me',
  REDIRECT_URI:'https://stpoolingportal.stoltdev.com/dashboard',
  POST_LOGOUT_REDIRECT_URI:'https://stpoolingportal.stoltdev.com',
  googleAnalytic:'dev',
  googleAnalyticKey:'G-MF7QPGVZVN',
  googleTagManager:'GTM-MJDVZX7',
  googleAnalyticUAKey:'UA-277164073-1',
  GET_IP_ADDRESS_API :"https://ipwhois.app/json/"
};
     

 