import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import {
  ButtonModule,
  RadioButtonModule,
  CheckboxModule,
  IconModule,
  LinkModule,
  TooltipModule,
  InputModule,
  PillsModule,
  AccordionModule,
  StatusModule,
  PageTabModule,
  CardModule,
  ExplodedDropdownModule,
  DatePickerModule,
  DateModule,
  ModalModule,
  NavigationModule,
  TableModule,
  DirectiveModule,
  DropdownModule,
  ProfileImageModule,
  RangeSliderModule,
  HeaderStandaloneModule,
  BreadcrumbModule,
  ActionPanelModule,
  TimePickerModule,
  PageTitleModule
  
} from 'snl-complib';


const snlComplibModule: any[] = [
  ButtonModule,
  RadioButtonModule,
  CheckboxModule,
  IconModule,
  LinkModule,
  TooltipModule,
  InputModule,
  PillsModule,
  AccordionModule,
  StatusModule,
  PageTabModule,
  CardModule,
  ExplodedDropdownModule,
  DatePickerModule,
  DateModule,
  ModalModule,
  NavigationModule,
  TableModule,
  DirectiveModule,
  DropdownModule,
  ProfileImageModule,
  RangeSliderModule,
  HeaderStandaloneModule,
  BreadcrumbModule,
  ActionPanelModule,
  TimePickerModule,
  PageTitleModule
  
  ];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HighchartsChartModule,
    ...snlComplibModule
    
  ],
  exports: [
    HighchartsChartModule,
    ...snlComplibModule
  ]
})
export class SharedSnlComplibModule { }
