import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FrontModule } from './front/front.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SnlComponentsModule } from 'snl-complib';
import { msalConfig } from './front/auth/auth-config';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalModule, MsalService, MsalRedirectComponent } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FrontModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SnlComponentsModule,
    NgSelectModule,
    ToastrModule.forRoot(),
    MsalModule.forRoot(new PublicClientApplication(msalConfig),
    {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['https://graph.microsoft.com/User.Read', "https://graph.microsoft.com/profile"]
      },
      loginFailedRoute: '/'
    }, {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map<string, Array<string> | null>([
        ["https://graph.microsoft.com/v1.0/me", ["https://graph.microsoft.com/User.Read", "https://graph.microsoft.com/profile"]],
        [environment.graphQL_URL, [environment.USERSCOPE]],
      ]),
  })
  ],
  exports: [SnlComponentsModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent,MsalRedirectComponent]
})
export class AppModule { }
