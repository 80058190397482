<header>
  <a ng-reflect-router-link="/" href="/">
      <img alt="stolt-tankers-logo" src="assets/img/stolt-tankers-logo.svg">
  </a>
</header>

<section class="Wrapper404Main">
    <div class="Wrapper404">
      <div class="contarea">
        <h1 class="header-h2 df-c col-gap-8">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" _ngcontent-vos-c37="" aria-hidden="true">
            <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#F33A3A" stroke-width="2" stroke-miterlimit="10" _ngcontent-vos-c37=""></path>
            <path d="M16 10V17" stroke="#F33A3A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" _ngcontent-vos-c37=""></path>
            <path d="M17.4375 21.5C17.4375 22.2939 16.7939 22.9375 16 22.9375C15.2061 22.9375 14.5625 22.2939 14.5625 21.5C14.5625 20.7061 15.2061 20.0625 16 20.0625C16.7939 20.0625 17.4375 20.7061 17.4375 21.5Z" fill="#F33A3A" stroke="#F33A3A" stroke-width="0.125" _ngcontent-vos-c37=""></path>
          </svg>
          Access denied!
        </h1>
        <p class="body-b1">
          You are not authorized to perform this action. <br>Please request the required privileges from the site administrator.</p> 

        <div class="ButtonHolder404 df-c"> 
          <snl-button (click) = 'logout()' [type]="'primary'" [size]="'large'" [disabled]="false">
            <span class="df-c col-gap-8">
              Try logging in with a different account
              <snl-icon icon="fa fa-chevron-right"></snl-icon> 
            </span> 
        </snl-button>
          
        </div>
      </div>  

        <div class="banner-404-hld">
            <img src="assets/img/banner-access-denied.jpg" alt="img">
        </div> 
    </div>
</section>